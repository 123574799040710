import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

const domain_name = "OPPERODE-HARZ.de"


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {
            modalInfo:''
        }
  }
  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };

 loadJSON=(callback)=> {   

    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', './data/my_data.json', true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);  
 }

 
  
  

  render(){
  return (
    <div class="modul">

      <Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered> 
            <Modal.Header >
                <Modal.Title>Bilder hochladen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
           
           
            
            
            <form action="upload.php" method="post" enctype="multipart/form-data">
              

              <input class="" type="file" name="fileToUpload" id="fileToUpload" hidden="hidden" onClick={()=>{

              }} onChange={()=>{
                  if(document.getElementById("fileToUpload").value){
                    document.getElementById("custom-text").innerHTML = document.getElementById("fileToUpload").value.match(/[\/\\]([\w\d\s\.\-\(\)]+)$/)[1];
                  }else{
                    document.getElementById("custom-text").innerHTML = "no file sel";                      
                  }
              }}/>
              <div class="modal-input" id="modal-input" onClick={()=>{
                  document.getElementById("fileToUpload").click();
              }}>Bild auswählen:</div>

              <span id="custom-text">Kein Bild ausgewählt!</span>


              <input  type="submit" value="Hochladen" name="submit" id="submit-button" hidden="hidden"/>

              <div class="modal-input" onClick={()=>{
                  document.getElementById("submit-button").click();
              }}>Hochladen</div>

              
            </form>  
              </Modal.Body>
              <Modal.Footer>
                {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
                
              </Modal.Footer>
            </Modal> 
             
          </div>
  )
  };
}

export default App;
