import React, { Component } from 'react';
import ReactDOM from 'react-dom';



class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }
  componentDidMount(){
    
  }
render(){
  return ( 
    <div class="fixed-bottom mx-auto" style={{
      maxWidth:940,
      paddingLeft:10,
      paddingRight:10,
      fontSize:20,
      bottom:10,
      
       
       }}>

      <div class="" >
        <div class="" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
          <img src="./images/menue_light.png" class="img-fluit" style={{width:25}}/>
        </div>
      
      <div class="collapse" id="collapseExample">
        
        
       <div class="footer-content" >
            {/* <div class="p-1 my-auto">
              <div class="h5 text-left">{domain_name}</div>
            </div> */}

            <div class="p-1" >
              <div class=""  >Betreiber:</div> 
              <div class="p-1" >Whoami-webprojekte.de</div> 
              {/* <div class="">-</div> 
              <div class="">webprojekte.de</div>   */}
            </div>

           {/*  <div class="px-3 text-light" style={{fontFamily: 'Dosis-Light'}}>
              <div class="border-bottom border-light"  >Adresse</div>   
              <div class="">Musterstrasse</div>   
              <div class="">18</div> 
              <div class="">06493 </div>           
              <div class="">Musterstadt </div>  
            </div> */}
            <div class="p-1" style={{fontFamily: 'Dosis-Light'}}>     
              <div class="">Email:</div>
              <div class="p-1">info@opperode-harz.de</div>
            </div>
            <div id="counter bg-danger">
              <div>Sie sind Besucher Nr.:</div>
              {
                this.props.counter != null ?
                this.props.counter
              :null}
            </div>
            </div>

            

        
       </div>
      </div>
      </div>
        
    );
}
}

export default App;