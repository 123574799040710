import React, { Component } from 'react';
import ReactDOM from 'react-dom';



class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }

  

  render(){
    return (
        <div class="modul"> 
            <div class="h1 p-1 text-left text-dark border-bottom border-dark">Feuerwehr</div>
                
        </div>
    )
   }
}

export default App;