import React, { Component} from 'react';
import ReactDOM from 'react-dom';
import Modal from "react-bootstrap/Modal";

const domain_name = "OPPERODE-HARZ.de"


class App extends Component {
	constructor(props) {
		super(props);        
    this.state = {
        modalInfo:'',
        showModal: true,
        showSubModal: false,
        openResultModal:false,
        date: '',
        version:'v2020.09.13.0256'
    } 
    
    setInterval(() => {
      this.setState({
        date: new Date().toUTCString()
       })
    }, 1000);
  }

  componentDidMount(){
   
  }

 loadJSON=(callback)=> {   

    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', './data/my_data.json', true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);  
 }

 showModal = () => {
  this.setState({
    openModal:true
  }
  )
};

hideModal = () => {
this.setState({
  openModal:false
})
};


showSubModal = () => {
  this.setState({
    openSubModal:true
  }
  )
};

hideSubModal = () => {
this.setState({
  openSubModal:false
})
};
 
componentDidMount(){
  this.setState({
    showModal: true,
    showSubModal: false,
    openResultModal:false
  },()=>{
    this.showModal();      
  })    
}

  showResultModal = () => {
    this.setState({
      openResultModal:true
    }
    )
  };
  
  hideResultModal = () => {
  this.setState({
    openResultModal:false
  })
  };
   
    componentDidMount(){
      this.setState({
        showModal: true,
        showSubModal: false,
        openResultModal:false
      },()=>{
        this.showModal();      
      })    
    }

  logIn=()=>{
    let _this =  this;
    var nameName = document.getElementById('i_email').name
    var nameValue = document.getElementById('i_email').value

    var passwordName = document.getElementById('i_pw').name
    var passwordValue = document.getElementById('i_pw').value

    var toSend = encodeURI(''                        
                        + nameName + "=" + nameValue 
                        + "&" + passwordName + "=" + passwordValue
                       
                    ) 

    console.log(toSend)                
    
    var xhttp = new XMLHttpRequest();

    xhttp.onreadystatechange = function() {        
        if (this.readyState == 4 && this.status == 200) {
          console.log("gesendet");  

          console.log(xhttp.responseText);

          _this.setState({
            answer: xhttp.responseText,
            file: JSON.parse(xhttp.responseText).file,
            name: '',
            password: ''          
          },()=>{
            if(JSON.parse(xhttp.responseText).error != null){
              _this.setState({
                answer: JSON.parse(xhttp.responseText).error_msg});
              _this.showSubModal();

              if(JSON.parse(xhttp.responseText).error === 0){
                _this.hideModal();
                _this.showResultModal();
              }

            }else{
              
              
            }
            
           
          })              
        }
    };

    xhttp.open("POST", "http://whoami-webprojekte.net/login.php", true);
    //xhttp.open("POST", "./login.php", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

   xhttp.send(toSend);
  

  }

  logOut=()=>{
    this.setState({
      showModal: true,
      showSubModal: false,
      openResultModal:false

    }, ()=>{
      this.hideSubModal();
      this.hideResultModal();
      this.showModal()})
  }
  

  render(){
  return (
    <div class="">
      <div id="result"></div>

    <Modal  show={this.state.openModal} onHide={this.hideModal}
      dialogClassName="my-modal"
      size="sz"      
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered> 
      <Modal.Header >
          <Modal.Title>Log in with your email and password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form class="" action="/action_page.php">

                    <div class=" text-white">
                     <div id="l_email" class="blend" for="email"></div>
                      <input id="i_email" name="iemail" type="text" class="my-textinput" placeholder="Email"  
                      onFocus={()=>{
                        document.getElementById("i_email").placeholder=""
                        document.getElementById("l_email").innerText="Email"
                        document.getElementById("l_email").style.fontSize = '18px';
                      }}

                      onBlur={()=>{
                        document.getElementById("i_email").placeholder="Email"
                        document.getElementById("l_email").innerText=""
                        document.getElementById("l_email").style.fontSize = '22px';
                      }}
                      />
                    </div>



                    <div class="">
                      <div id="l_pw" class="blend" for="pwd"></div>
                      <input id="i_pw" name="ipw" type="password" class="my-textinput" placeholder="Password"
                      onFocus={()=>{
                        document.getElementById("i_pw").placeholder=""
                        document.getElementById("l_pw").innerText="Password"
                        document.getElementById("l_pw").style.fontSize = '18px';
                      }}

                      onBlur={()=>{
                        document.getElementById("i_pw").placeholder="Password"
                        document.getElementById("l_pw").innerText=""
                        document.getElementById("l_pw").style.fontSize = '22px';
                      }}/><img id="eye" width="25px" src="./images/eye_slash.png" onClick={()=>{
                        
                        if(document.getElementById("i_pw").type === "password"){
                          document.getElementById("i_pw").type = "text";
                          document.getElementById("eye").src = "./images/eye.png";
                        }else{
                          if(document.getElementById("i_pw").type === "text"){
                            document.getElementById("i_pw").type = "password";
                            document.getElementById("eye").src = "./images/eye_slash.png";
                          }
                        }

                        
                      }}/>
                    </div>

                    {/* <div class="checkbox">
                      <label><input type="checkbox"/> Remember me</label>
                    </div> */}

                    <div class="modal-button" onClick={()=>{
                     this.logIn();
                    }}>Log in</div>
                  </form> 
        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
                  <div>{this.state.date}</div>
                  <div>{this.state.version}</div>
        </Modal.Footer>
      </Modal> 
{/* //////////////////////////////////////////SUB/////////////////////////////////// */}
      <Modal  show={this.state.openSubModal} onHide={this.hideSubModal} 
        size="sm"
        dialogClassName="my-submodal"
      > 
      <Modal.Header >
          {/* <Modal.Title>Error</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
       <div>{this.state.answer}</div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
          
        </Modal.Footer>
      </Modal> 

    {/*   //////////////////////////ResulT////////////////////////////////////////////// */}

      <Modal  show={this.state.openResultModal} onHide={this.hideResultModal} 
         dialogClassName="my-modal"
         size="sz"      
         aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
         centered
      > 
      <Modal.Header >
          <Modal.Title>Webprojekte</Modal.Title>
      </Modal.Header>
      <Modal.Body>
       <div>
         <ul>
          <li><a href="http://kassner.whoami-webprojekte.net/" target="_blank" >Kassner</a></li>
          <li><a href="http://kassner1.whoami-webprojekte.net/" target="_blank">Kassner 1</a></li>
          <li><a href="http://opperode-harz.de/" target="_blank">Opperode</a></li>
          <li><a href="http://huepfburg-harz.de/" target="_blank">Hüpfburg</a></li>
         </ul>
         </div>
        </Modal.Body>
        <Modal.Footer>
        <div class="modal-button" onClick={()=>{
                     this.logOut();
                    }}>Log out</div>
          
        </Modal.Footer>
      </Modal>
      
            
          </div>
  )
  };
}

export default App;
