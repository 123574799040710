import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

const domain_name = "OPPERODE-HARZ.DE"


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }
  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };


  
  

  render(){
  return (
    <div class="modul">

<Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered> 
      <Modal.Header >
          <Modal.Title>Herzlich Willkommen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div class="text-light" style={{top:10, padding:10, width:200, margin:'0 auto'}}>
              <div class="text-center" >
                  Hier entsteht das Internetportal
              </div>

              <div class="text-center" style={{fontFamily: 'Dosis-Bold', }}>
                  {domain_name}
              </div>
          </div> 
        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
          
        </Modal.Footer>
      </Modal> 
      
          </div>
  )
  };
}

export default App;
