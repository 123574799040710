import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

const domain_name = "opperode-harz.de"


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }
  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };

  

  render(){
  return (
    <div class="modul">
      <Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered> 
      <Modal.Header >
          <Modal.Title>Historisches</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div class="text-justify" style={{fontFamily: 'Dosis-Light'}}>
                Eine gewisse regionale Bedeutung erlangte Opperode als Abbaugebiet von Steinkohle und einzigem Steinkohlerevier von Anhalt-Bernburg. 
                Der Steinkohleabbau dürfte bereits im frühen 16. 
                Jahrhundert begonnen haben. Im Jahr 1573 erfolgte die Genehmigung zur Anlegung eines neuen Schachts. 
                Von 1740 bis 1824 erfolgte ein intensiver Steinkohlebergbau. 
                Die Kohle wurde vor allem an die Kupferhütten im Mansfelder Land und die Salinen in Staßfurt geliefert. 
                Von 1852 bis 1860 wurde erneut versucht Steinkohle abzubauen. 
                Im Jahr 1869 wurde der Steinkohlebergbau in Opperode endgültig eingestellt.
                Im Jahr 1933 lebten im Ort 505 Menschen, 1939 war die Zahl auf 535 Einwohner angestiegen. 
                Bis zum 30. Juni 1950 war Opperode eine selbständige Gemeinde und wurde dann nach Ballenstedt eingemeindet.
            </div>
            <div>[Wikipedia]</div> 
        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
          
        </Modal.Footer>
      </Modal> 
             
          </div>
  )
  };
}

export default App;
