import React, { Component } from 'react';
import ReactDOM from 'react-dom';


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {
            contentJson: null
        }
  }

  loadJSON=(callback)=> {   

    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', './data/veranstaltungen.json', true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);  
 }

    componentDidMount(){
        var thisClass = this;
        this.loadJSON(function(response) {
        
            
            thisClass.setState({
                contentJson: JSON.parse(response)
            });
            
            
        });
    }

  render(){
    return (
        <div class="modul">
             <div class="h1 p-1 text-left text-dark border-bottom border-dark">Lokale Veranstaltungen</div>

            {
                this.state.contentJson != null?
                this.state.contentJson.veranstaltungen.map((veranstaltung)=>(
                    <div class="p-2">
                        <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>{veranstaltung.titel}</div>
                        <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:24}}>
                            { veranstaltung.thema.titel}
                            { veranstaltung.thema.inhalt}
                        </div>
                        <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:24}}>                    
                        { veranstaltung.ort.titel}
                        { veranstaltung.ort.inhalt}
                        </div>
                        <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:24}}>                    
                        { veranstaltung.inhalt.titel}
                        { veranstaltung.inhalt.inhalt}
                        </div>
                    </div> 
                ))
                :null
            }         

        </div>
    )
   }
}

export default App;