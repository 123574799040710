import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

const domain_name = "OPPERODE-HARZ.de"


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {
            modalInfo:''
        }
  }
  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };

 loadJSON=(callback)=> {   

    var xobj = new XMLHttpRequest();
        xobj.overrideMimeType("application/json");
    xobj.open('GET', './data/my_data.json', true); // Replace 'my_data' with the path to your file
    xobj.onreadystatechange = function () {
          if (xobj.readyState == 4 && xobj.status == "200") {
            // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
            callback(xobj.responseText);
          }
    };
    xobj.send(null);  
 }

 
  
  

  render(){
  return (
    <div class="modul">

      <Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            > 
            <Modal.Header >
                <Modal.Title>Impressum</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div class="text-center ">
            {/* <div class="p-1 my-auto">
              <div class="h5 text-left">{domain_name}</div>
            </div> */}

            <div class="p-1">
              <div class=""><span  class="border-bottom border-dark">Betreiber</span></div> 
              <div class="">Whoami-webprojekte.de</div>  
            </div>

            {/* <div class="">
              <div class=""><span  class="border-bottom border-dark">Adresse</span></div>       
              <div class="">06493 Musterstadt </div>           
              <div class="">Musterstrasse 18</div>  
            </div> */}
            <div class="p-1">     
              <div class=""><span  class="border-bottom border-dark">Email</span></div>
              <div class="">info@opperode-harz.de</div>
            </div>
            </div>


            <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="mod-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalCenterTitle">Information</h5>
                    {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button> */}
                </div>
                <div class="modal-body">
                    {this.state.modalInfo}
                </div>
                <div class="modal-footer">
                    {/* <button type="button" class="btn btn-secondary" data-dismiss="modal">Schliessen</button> */}
                    
                </div>
                </div>
            </div>
            </div>



            <div class="modal-button" data-toggle="modal" data-target="#exampleModalCenter" onClick={()=>{
                var thisClass = this;
                this.loadJSON(function(response) {
                    // Parse JSON string into object
                    
                    thisClass.setState({
                        modalInfo: JSON.parse(response).data.ok
                    });
                    
                    
                });
            }}>Prüfen</div>
            
            
              </Modal.Body>
              <Modal.Footer>
                {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
                
              </Modal.Footer>
            </Modal> 
             
          </div>
  )
  };
}

export default App;
