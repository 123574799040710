import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './App.css';



import Navbar from './Navbar';
import Bildershow from './Bildershow';
import Willkommen from './Willkommen';
import Administration from './Administration';

import Footer from './Footer'


const domain_name = "OPPERODE-HARZ.DE"


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {
          counter:null
        }
  }

  componentDidMount(){
   
    this.getCounter();
  }

  getCounter=()=>{
    var xhttp = new XMLHttpRequest();
    var thisClass = this;
    xhttp.onreadystatechange = function() {
        
        if (this.readyState == 4 && this.status == 200) {
            thisClass.setState({
            counter: xhttp.response
        },()=>{
          //ReactDOM.render(<Navbar/>, document.getElementById('navbar'));
          //ReactDOM.render(<Willkommen/>, document.getElementById('maincontent')); 
          //ReactDOM.render(<Footer counter={thisClass.state.counter}/>, document.getElementById('footer')); 
          ReactDOM.render(<Administration/>, document.getElementById('maincontent'));
        })
        }
    };

    xhttp.open("GET", "./getCounter.php", true);
    xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");


    xhttp.send();

  }

render(){
  return (   
        
      <div className="App">
        <div id="navbar" class="header fixed-top" ></div>               
       <div id='picturesh ow' class=" " >  </div>  
        <div id='maincontent' class="content" >  </div>
        
        <div id="footer" class="footer fixed-bottom"></div>      
      </div>
  
  );
}
}

export default App;
