import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Willkommen from './Willkommen';
import Veranstaltungen from './Veranstaltungen'
import Datenschutz from './Datenschutz';
import Geschichte from './Geschichte'
import Kontakt from './Kontakt'
import Impressum from './Impressum'
import UploadImage from './UploadImage'
import Administration from './Administration'
import Landfrauen from './Landfrauen'
import Maennerchor from './Maennerchor'
import Feuerwehr from './Feuerwehr'

const domain_name = "opperode-harz.de"

class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }
  componentDidMount(){
    
  }
render(){
  return (        
       <div class="header" > 
            <nav class="navbar navbar-expand-lg navbar-dark " >
                <button class="btn text-light navbar-brand " 
                    onClick={()=>{
                    ReactDOM.render(<Willkommen/>, document.getElementById('maincontent')
                    );
                }}><div class="border-bottom border-light">{domain_name}</div></button>
                
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <img src="./images/menue_light.png" class="img-fluit" style={{width:25}}/>
        
                </button>
        
                <div class="collapse navbar-collapse text-left" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active px-1 my-button" style={{borderRightWidth: 1, borderRightColor:'white'}}>
                            <button class="btn text-light "  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                             onClick={()=>{
                                ReactDOM.render(<Willkommen/>, document.getElementById('maincontent')
                                );
                            }}
                            >Willkommen <span class="sr-only">(current)</span></button>
                        </li>
                        <li class="nav-item active px-1 my-button">
                            <button class="btn text-light "  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                            onClick={()=>{
                                ReactDOM.render(<Geschichte/>, document.getElementById('maincontent')
                                );
                            }}
                            >Geschichte<span class="sr-only">(current)</span></button>
                        </li>

                       {/*  <li class="nav-item dropdown px-1">
                            <button class="btn text-light dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style={{fontFamily: 'Dosis-Light',}}
                            > 
                                Vereine
                            </button>

                        <div class="dropdown-menu px-1" aria-labelledby="navbarDropdown" style={{backgroundColor:'rgb(52,58,64)',backgroundColor:'rgba(52,58,64,0.9)',}}>
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                             onClick={()=>{
                                ReactDOM.render(<Landfrauen/>, document.getElementById('maincontent')
                                );
                            }}
                            >Landfrauen</button>
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent"
                            onClick={()=>{
                                ReactDOM.render(<Maennerchor/>, document.getElementById('maincontent')
                                );
                            }}
                            >Männerchor</button>
                           
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                            onClick={()=>{
                                ReactDOM.render(<Feuerwehr/>, document.getElementById('maincontent')
                                );
                            }}
                            >Feuerwehr</button>
                        </div>
                        </li> */}



                        {/* <li class="nav-item px-1">
                            <button class="btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                                onClick={()=>{
                                    ReactDOM.render(<Veranstaltungen/>, document.getElementById('maincontent')
                                    );
                                }}
                            >Veranstaltungen <span class="sr-only">(current)</span></button>
                        </li> */}

                        <li class="nav-item dropdown px-1">
                            <button class="btn  text-light dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            style={{fontFamily: 'Dosis-Light',}}
                            >
                                Weiteres
                            </button>

                        <div class="dropdown-menu sub-menue" aria-labelledby="navbarDropdown" >
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}} 
                             onClick={()=>{
                                ReactDOM.render(<Kontakt/>, document.getElementById('maincontent')
                                );
                            }}
                            >Kontakt</button>
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                            onClick={()=>{
                                ReactDOM.render(<Datenschutz/>, document.getElementById('maincontent')
                                );
                            }}
                            >Datenschutz</button>
                            {/* <div class="dropdown-divider"></div> */}
                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                            onClick={()=>{
                                ReactDOM.render(<Impressum/>, document.getElementById('maincontent')
                                );
                            }}
                            >Impressum</button>

                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                                onClick={()=>{
                                ReactDOM.render(<UploadImage/>, document.getElementById('maincontent')
                                );
                            }}
                            >Bilder hochladen</button>

                            <button class=" btn text-light"  data-toggle="collapse" data-target="#navbarSupportedContent" style={{fontFamily: 'Dosis-Light',}}
                            onClick={()=>{
                                ReactDOM.render(<Administration/>, document.getElementById('maincontent'));
                            }}
                            >Administration</button>
                        </div>
                        </li>
                        
                    </ul>
                    
                </div>
            </nav>
        </div>
  );
}
}

export default App;