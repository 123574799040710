import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";

class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {
            modalInfo: ''
        }
  }

  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };

  
  sendForm=()=>{
    
    if(document.getElementById('gridCheck').checked){
        var emailName = document.getElementById('email').name
        var emailValue = document.getElementById('email').value

        var commentName = document.getElementById('comment').name
        var commentValue = document.getElementById('comment').value


        var toSend = encodeURI('' 
                    
                    + emailName + "=" + emailValue                      
                    + "&" + commentName + "=" + commentValue
                ) 
    
        
        
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            
            if (this.readyState == 4 && this.status == 200) {
                this.setState({
                modalInfo: '200'
            })
            }
        };

        xhttp.open("POST", "http://opperode-harz.de/mail.php", true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

        if (emailValue != '' && commentValue != ''){
            xhttp.send(toSend);
        
            this.setState({
                modalInfo: 'Kommentar gesendet ...'
            })
        
        }else{
            this.setState({
                modalInfo: 'Bitte beide Felder ausfüllen ...'
            })
        }
    }else{
      this.setState({
          modalInfo: 'Bitte bestätigen ...'
      })
    }
}

  render(){
    return (
        <div class="modul">

<Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered> 
      <Modal.Header >
          <Modal.Title>Kontakt ...</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form class="my-5" id="form_" class="">

    <div class="mx-3 text-left">                   
        <label class=" px-3" style={{fontFamily: 'Dosis-Light'}}>Email</label>
        <input id="email" type="email" class="form-control" name="email" placeholder="..."/>
                          
    </div>

    <div class="m-3 text-left">
        <label class=" px-3"  style={{fontFamily: 'Dosis-Light'}}>Ihre Meinung (max. 200 Zeichen)</label>
        <textarea id="comment" class="form-control" rows="4" cols="50" name="comment" form="usrform"/>
       {/*  <input type="text" class="form-control" id="inputAddress" placeholder="..."/> */}
    </div>

    <div class="mx-4 ">
        <div class="mx-4 text-left">
            <input class="form-check-input" type="checkbox" id="gridCheck"/>
            <label class="form-check-label" for="gridCheck">
                bestätigen
            </label>
        </div>
    </div>
    <div class="modal-button" data-toggle="modal" data-target="#exampleModalCenter" onClick={()=>{
                      this.sendForm()
                    }}>Abschicken</div>
    </form>
       


      <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="mod-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">Information</h5>
                {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button> */}
            </div>
            <div class="modal-body">
                {this.state.modalInfo}
            </div>
            <div class="modal-footer">
                <div type="button" class="modal-button" data-dismiss="modal">Schliessen</div>
                
            </div>
            </div>
        </div>
                
        </div>

        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
          
        </Modal.Footer>
      </Modal>
        </div>
    )
   }
}

export default App;