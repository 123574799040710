import React, { Component } from 'react';
import Modal from "react-bootstrap/Modal";


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }

  componentDidMount(){
    this.showModal()
  }

  showModal = () => {
    this.setState({
      openModal:true
    }
    )
  };
  
  hideModal = () => {
  this.setState({
    openModal:false
  })
  };

  

  render(){
    return (
        <div class="modul">
            <Modal  show={this.state.openModal} onHide={this.hideModal} size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered> 
      <Modal.Header >
          <Modal.Title>Datenschutzerklärung
          <div class="text-center font-italic">Allgemeiner Hinweis und Pflichtinformationen</div>
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div class="p-2 ">
                <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}> Benennung der verantwortlichen Stelle </div>
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:                
                    Max Mustermann                    
                    Musterstrasse 18
                    06493 Musterstadt
                    
                    Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung 
                    von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).                    
               </div>
            </div> 


            <div class="p-2 ">
                <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}> Widerruf Ihrer Einwilligung zur Datenverarbeitung</div>
                
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich.
                    Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. 
                    Für den Widerruf genügt eine formlose Mitteilung per E-Mail. 
                    Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </div>
            </div> 


            <div class="p-2">
            <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</div>

                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen 
                    Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. 
                    Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der 
                    Landesdatenschutzbeauftragte des Bundeslandes, 
                    in dem sich der Sitz unseres Unternehmens befindet. 
                    Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: 

                    <a 
                        class="px-2 text-dark text-center" 
                        style={{fontSize:16}} 
                        href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target='_blank'
                    >https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.
                    </a>
                
                </div>
            </div>  



            <div class="p-2">
            <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>Recht auf Datenübertragbarkeit</div>
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags 
                    automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem
                    maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen 
                    verlangen, erfolgt dies nur, soweit es technisch machbar ist.
                 </div>
            </div> 


            <div class="p-2">
                <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</div>
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft 
                    über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der 
                    Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich 
                    und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum 
                    aufgeführten Kontaktmöglichkeiten an uns wenden.
                </div>
            </div> 


            



            <div class="p-2">
                <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>SSL- bzw. TLS-Verschlüsselung</div>
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}>
                    Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, 
                    die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. 
                    TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, 
                    für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der 
                    „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                </div>
            </div> 


            <div class="p-2">
            <div class="text-left" style={{fontFamily: 'Dosis-Bold'}}>Kontaktformular</div>
                <div class="px-2 text-justify" style={{fontFamily: 'Dosis-Light', fontSize:16}}> 
                    Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert,
                    um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. 
                    Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.

                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich 
                    auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits 
                    erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. 
                    Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.

                    Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, 
                    Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. 
                    Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                </div>
            </div> 

            <div class="text-center" style={{fontFamily: 'Dosis-Bold', fontSize:16}}>Quelle: Datenschutz-Konfigurator von mein-datenschutzbeauftragter.de</div>
         
        </Modal.Body>
        <Modal.Footer>
          {/* <button class="btn btn-black" onClick={this.hideModal}>Schliessen</button> */}
          
        </Modal.Footer>
      </Modal> 
            </div>
    )
   }
}

export default App;