import React, { Component } from 'react';
import ReactDOM from 'react-dom';


class App extends Component {
	constructor(props) {
		super(props);        
        this.state = {}
  }

  

  render(){
    return (
      <div >
        <div id="carouselExampleCaptions" class="carousel slide  carousel-fade" data-ride="carousel">
           {/*  <ol class="carousel-indicators">
              <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            </ol> */}

            <div class="carousel-inner">
              <div class="carousel-item active" >
                <img src="./images/opperoder_banner10.png" class="img-fluid" alt="..."/>
                {/* <div class="carousel-caption d-none d-md-block">
                  <h5 class=" bg-dark">First slide label</h5>
                  <p class=" bg-dark">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </div> */}
            </div>

            <div class="carousel-item" >
              <img src="./images/opperoder_banner11.png" class="img-fluid" alt="..."/>
              {/* <div class="carousel-caption d-none d-md-block">
                <h5 class=" bg-dark">Second slide label</h5>
                <p class=" bg-dark">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
              </div> */}
            </div>

            <div class="carousel-item" >
              <img src="./images/opperoder_banner10.png" class="img-fluid" alt="..."/>
              {/* <div class="carousel-caption d-none d-md-block">
                <h5 class=" bg-dark">Third slide label</h5>
                <p class=" bg-dark">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </div> */}
            </div>

            <div class="carousel-item">
              <img src="./images/opperoder_banner11.png" class="img-fluid" alt="..."/>
              {/* <div class="carousel-caption d-none d-md-block">
                <h5 class=" bg-dark">Third slide label</h5>
                <p class=" bg-dark">Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
              </div> */}
            </div>
        </div>

       {/*  <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a> */}
      </div>
      </div>
    )
   }
}

export default App;